import React from 'react';
import {
  Box, Typography, Card, CardContent,
  CardHeader,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

function RemyPiron() {
  const { t } = useTranslation();
  const prefix = 'testimonies.remy_piron';

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      backgroundColor: 'deepPrimary.main',
      mt: -12,
      pt: 6,
    }}
    >
      <Box sx={{ px: { xs: 3, sm: '10%', xl: '15%' }, py: 12 }}>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          {t(`${prefix}.title`)}
        </Typography>

      </Box>
      {/*
      <Box sx={{ px: { xs: 3, sm: '10%', xl: '15%' } }}>
        <Typography variant="h4" color="ourOrange.main" sx={{ mb: -6 }}>
          {t(`${prefix}.context.title`)}
        </Typography>
      </Box> */}

      <Box sx={{
        position: 'relative',
        backgroundColor: 'lightBg.main',
        ml: { xs: 0, md: '5%', xl: '10%' },
        py: 8,
        borderTopLeftRadius: { xs: 0, md: 100, xl: 100 },
        // borderBottomLeftRadius: 10,
      }}
      >
        <Box sx={{
          pr: { xs: 3, sm: '10%', xl: '15%' },
          pl: { xs: 3, sm: '5%', xl: '5%' },
        }}
        >
          <Box component="section" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h4" color="primary.main">
              {t(`${prefix}.context.title`)}
            </Typography>
            <Typography sx={{ color: 'deepPrimary.main' }}>{t(`${prefix}.context.p1`)}</Typography>
            <Typography sx={{ color: 'deepPrimary.main' }}>{t(`${prefix}.context.p2`)}</Typography>
          </Box>
        </Box>
      </Box>

      {/* Rest of the sections with consistent padding */}
      <Box sx={{
        px: { xs: 3, sm: '10%', xl: '15%' },
        py: 14,
        backgroundColor: 'background.default',
      }}
      >
        {/* Feedback Section */}
        <Box component="section" sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Typography variant="h4" color="ourOrange.main">{t(`${prefix}.feedback.title`)}</Typography>

          {['time_management', 'user_experience', 'results', 'support'].map((key) => (
            <Box key={key} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="h5">{t(`${prefix}.feedback.${key}.title`)}</Typography>
              <Typography sx={{
                fontStyle: 'italic',
                pl: 2,
                borderLeft: '4px solid',
                borderColor: 'primary.main',
                fontSize: '1.1rem',
              }}
              >
                {t(`${prefix}.feedback.${key}.text`)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{
        position: 'relative',
        backgroundColor: 'lightBg.main',
      }}
      >
        <Box sx={{ px: { xs: 3, sm: '10%', xl: '15%' }, py: 12 }}>
          <Box component="section" sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Typography variant="h4" color="primary.main">{t(`${prefix}.solutions.title`)}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              {[
                { key: 'labox_inventory', link: '/inventories' },
                { key: 'digitank', link: '/gauged-containers' },
              ].map(({ key, link }) => (
                <Box
                  key={key}
                  component="a"
                  href={link}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    textDecoration: 'none',
                    '&:hover': {
                      '& .title': {
                        textDecoration: 'underline',
                      },
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    color="ourOrange.main"
                    className="title"
                  >
                    {t(`${prefix}.solutions.${key}.title`)}
                  </Typography>
                  <Typography sx={{ color: 'deepPrimary.main' }}>
                    {t(`${prefix}.solutions.${key}.text`)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box component="footer" sx={{ mt: 4, textAlign: 'center' }}>
            <Typography sx={{ color: 'deepPrimary.main' }}>
              {t(`${prefix}.footer.text`)}
              {' '}
              <Box
                component="a"
                href="https://distillerie-remy-piron.fr/"
                target="_blank"
                sx={{
                  color: 'ourOrange.main',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {t(`${prefix}.footer.link`)}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          borderRadius: 2,
          textAlign: 'center',
          my: 12,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography variant="h4">
          {t('demo.text')}
        </Typography>
        <Button
          size="large"
          component="a"
          href="/contact"
          variant="contained"
          color="primary"
          sx={{ alignSelf: 'center' }}
        >
          {t('demo.button')}
        </Button>

      </Box>
    </Box>

  );
}

export default RemyPiron;
