import React from 'react';
import Box from '@mui/material/Box';
import {
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { getAnalytics } from 'firebase/analytics';
import {
  useFirestore,
} from 'reactfire';
import {
  addDoc,
  collection,
} from 'firebase/firestore';
import Welcome from './Welcome';
import Contact from './Contact';
import TankerTrucks from './ourSolutions/TankerTrucks';
import GaugedContainers from './ourSolutions/GaugedContainers';
import Alcoholometry from './ourSolutions/Alcoholometry';
import Pierre from './ourTeam/Pierre';
import Remi from './ourTeam/Remi';
import Evelyne from './ourTeam/Evelyne';
import Inventories from './ourSolutions/Inventories';
import Optimix from './ourSolutions/Optimix';
import RemyPiron from './testimonies/RemyPiron';

export default function LaboxAppsRoutes() {
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const db = useFirestore();

  const sendEmail = async (values) => {
    const emailContent = `Email: 
      ${values.email}

      Nom: 
      ${values.nameAndCompany}

      Message: 
      ${values.message}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@labox-apps.com',
        message: {
          subject: `[Labox Apps, Contact] ${values.nameAndCompany}`,
          text: emailContent,
        },
      },
    );
  };

  return (
    <Routes>
      <Route path="/*" element={<Welcome />} />
      <Route
        path="contact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
          />
        )}
      />
      <Route path="/tanker-trucks" element={<TankerTrucks />} />
      <Route path="/gauged-containers" element={<GaugedContainers />} />
      <Route path="/alcoholometry" element={<Alcoholometry />} />
      <Route path="/inventories" element={<Inventories />} />
      <Route path="/optimix" element={<Optimix />} />
      <Route path="/pierre" element={<Pierre />} />
      <Route path="/remi" element={<Remi />} />
      <Route path="/evelyne" element={<Evelyne />} />
      <Route path="/testimonies/remy-piron" element={<RemyPiron />} />
      <Route
        path="*"
        element={(
          <Box>
            <p>There is nothing here!</p>
          </Box>
        )}
      />
    </Routes>
  );
}
